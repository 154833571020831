body {
  margin: 0;
  padding: 0;
}

html,
#root,
.container,
body {
  height: 100%;
}

html {
  font-size: 16px;
}

.loadingAbsolute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  flex-direction: column;
}

.rightSpacingChildren > :not(:first-child) {
  margin-left: 16px;
}
